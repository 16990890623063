<template>
  <v-dialog
      v-model="dialog"
      max-width="800px"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title class="text-h5">
          <span>Quick view address book</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <b>Full name </b>{{addressBook.first_name}} {{addressBook.middle_name}} {{addressBook.last_name}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Email: </b>{{addressBook.email || 'N/A'}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Phone: </b>{{addressBook.phone || addressBook.work_phone}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Category: </b>{{addressBook.category || 'N/A'}}
            </v-col>

            <v-col cols="12" md="4">
              <b>Website: </b> <a v-if="addressBook.website" :href="addressBook.website">Link</a> <span v-else>N/A</span>
            </v-col>

            <v-col cols="12" md="4">
              <b>Status: </b>
              <span class="badge badge-success text-lg`"
                    v-bind:class="{ 'badge-success': addressBook.is_active, 'badge-danger': !addressBook.is_active }"
              >{{ addressBook.status_text }}</span>
            </v-col>

            <v-col cols="12" md="4">
              <b>Message: </b> <span v-html="addressBook.remarks"></span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="mr-2" text dark
            medium
            @click="dialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "QuickViewAddressBook",
  data() {
    return {
      dialog: false,
      addressBook: {}
    }
  },
  methods: {
    quickViewDialog(item) {
      this.dialog = true;
      this.addressBook = item;
    }
  }
}
</script>