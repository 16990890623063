<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Address book</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Address book
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn v-if="checkIsAccessible('address-book', 'create')" @click="addressBookCategory()"
                         class="mt-4 btn btn-primary" style="color: #fff">
                    Category
                  </v-btn>

                  <v-btn v-if="checkIsAccessible('address-book', 'create')" @click="createOrUpdateAddressBook()"
                         class="mt-4 ml-3 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new contact
                  </v-btn>

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="7">
                  <v-text-field label="Search by name, email or contact" v-on:keyup.enter="searchAddressBook" clearable
                                v-model="search.info" outlined dense></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select v-on:keyup.enter="searchAddressBook"
                            :items="status"
                            v-model="search.is_active"
                            label="Status"
                            item-text="name"
                            item-value="value"
                            outlined
                            dense clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-btn :loading="isLoading"
                         @click="searchAddressBook"
                         class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="px-3">
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Category</th>
                  <th>Remarks</th>
                  <th>Status</th>
                  <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="addressBooks.length > 0" v-for="item in addressBooks">
                    <td>
                      <a @click="createOrUpdateAddressBook(item.id)"
                         class="text-primary text-hover-primary mb-1">
                        {{ item.full_name }}
                      </a>
                        <a v-if="item.website" :href="item.website" target="_blank" class="text-info font-weight-bolder text-hover-primary mb-1 font-size-lg">
                            <i class="fa fa-link"></i>
                        </a>
                    </td>
                    <td>
                      <a class="text-info text-hover-primary mb-1 font-size-lg">
                        {{ item.email ? item.email : 'N/A' }}
                      </a>
                    </td>
                    <td class="px-2">
                        {{ item.mobile_format || item.phone_format }}
                    </td>

                    <td class="px-2">
                      {{ item.category || 'N/A' }}
                    </td>

                    <td class="px-2">
                        <span v-html="item.remarks"></span>
                    </td>

                    <td>
                          <span class="badge badge-success text-lg`"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': item.is_active==0 }"
                          >{{ item.status_text }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('address-book', 'show')">
                              <a @click="quickView(item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">mdi-eye</v-icon>
                                </span>
                                <span class="navi-text">Quick view</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('address-book', 'edit')">
                              <a @click="createOrUpdateAddressBook(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">mdi-pencil</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item"
                                             v-if="checkIsAccessible('address-book', 'delete')">
                              <a @click="deleteAddressBook(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="addressBooks.length == 0">
                    <td colspan="7" class="text-center">
                      No items added
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="addressBooks.length > 0"
                  class="pull-right mt-7"
                  @input="getAllAddressBook"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <quick-view-address-book ref="quick-view"></quick-view-address-book>
  </v-app>
</template>
<script>
import AddressBookService from "@/services/address-book/AddressBookService";
import QuickViewAddressBook from "@/view/pages/view/address-book/QuickViewAddressBook";

const addressBook = new AddressBookService;
export default {
  components: {QuickViewAddressBook},
  data() {
    return {
      isLoading: false,
      search: {
        info: '',
        is_active: '',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      total: null,
      perPage: null,
      page: null,
      addressBooks: [],
    }
  },
  methods: {
    getAllAddressBook() {
      this.isLoading = true;
      addressBook
          .paginate(this.search, this.page)
          .then(response => {
            this.addressBooks = response.data.data;
            this.isLoading = false;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          });
    },
    addressBookCategory() {
      this.$router.push({
        name: "address-book-category",
      });
    },
    quickView(item) {
      this.$refs['quick-view'].quickViewDialog(item);
    },
    createOrUpdateAddressBook(addressBookId) {

      if (addressBookId == null || addressBookId == undefined)
        this.$router.push({
          name: "address-book-create",
        });
      else
        this.$router.push({
          name: 'address-book-edit',
          params: {addressBookId: addressBookId}
        })
    },
    searchAddressBook() {
      this.getAllAddressBook();
    },

    deleteAddressBook(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            addressBook
                .delete(item)
                .then((response) => {
                  this.getAllAddressBook();
                  this.$snotify.success("Address Book Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  },
  mounted() {
    this.getAllAddressBook();
  }
}
</script>